.page {
    max-width: 86%;
    margin: 30px auto;
}

.header {
    h5 {
        color: rgba(35, 56, 98, 0.88);
        font-size: 18px;
        line-height: 150%;
    }

    h4 {
        color: rgba(35, 56, 98, 0.88);
        font-family: var(--font-semibold);
        font-size: 22px;
        line-height: 140%; /* 30.8px */
    }
}

.overviewInfo {
    display: flex;
    gap: 8px;
    margin-top: 16px;

    & > div {
        border-radius: 14px;
        border: 1px solid #e2eeff;
        background: #f9fcff;
        box-shadow: none;
        padding: 16px;

        h4 {
            color: #3d5075;
            font-size: 12px;
            font-style: normal;
            font-family: var(--font-medium);
            line-height: 24px;
            letter-spacing: 0.78px;
            text-transform: uppercase;
        }

        p {
            color: var(--gray-scale-000000, #070c18);
            font-family: var(--font-bold);
            font-size: 18px;
            line-height: 24px;
            margin-top: 8px;
        }
    }
}

.iconDiv {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: #e2eeff;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin: auto;
        width: 24px;
        height: 24px;
        stroke: #004aad;
    }
}

.mainContent {
    margin-top: 32px;
}

.overviewTable {
    width: 100%;
    border-radius: 14px;
    border: 1px solid #e2eeff;
    background: #f9fcff;
    padding: 0px 29px;
}

.overviewTableHeaders {
    display: grid;
    padding: 38px 24px;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 12px;
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
    width: 100%;

    span {
        color: rgba(35, 56, 98, 0.88);
        font-size: 12px;
        font-family: var(--font-medium);
        line-height: 24px; /* 200% */
        letter-spacing: 0.78px;
        text-transform: uppercase;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.overviewTableBody {
    width: 100%;
    margin-top: -14px;
}

.overviewTableRow {
    margin: 14px 0;
    display: grid;
    padding: 16px 24px;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 12px;
    width: 100%;
    align-items: center;
    border-radius: 16px;
    background: #fff;

    @media screen and (max-width: 600px) {
        display: none;
    }

    p,
    span {
        color: #000;
        font-size: 16px;
        line-height: 150%; /* 24px */
    }

    button {
        padding: 14px 16px;
        border: 0.5px solid #004aad;
        border-radius: 30px;
        font-family: var(--font-bold);
        span {
            color: #004aad;
            font-size: 13px;
            line-height: normal;
        }

        &:hover {
            span {
                color: #fff;
            }
        }
    }

    .profileType {
        text-transform: lowercase;

        &:first-letter {
            text-transform: capitalize;
        }
    }
}

.clusterNameAndLogo {
    display: flex;
    gap: 8px;
    align-items: center;

    img {
        width: 42px;
        height: 42px;
        border-radius: 100%;
    }
}

.paginationContainer {
    text-align: center;
    margin-top: 42px;
}
