.sectionTitle {
    font: 14px/21px var(--font-regular);
    margin: 0 0 8px 0;
    color: #233862;

    @media only screen and (max-width: 500px) {
        font: 12px/18px var(--font-regular);
    }
}

.directorFullName,
.directorContactInfo {
    > * {
        width: 100%;
    }
}

.directorFullName,
.directorContactInfo {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        gap: 0px;
    }
}
