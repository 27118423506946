.bvnDataContainer {
    margin: 24px 0;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 14px;
    padding: 16px 14px;
    gap: 17px;
    border: 1px solid #e2eeff;

    .check_failure {
        display: flex;
        align-items: center;
        gap: 8px;
        h4 {
            margin: auto 0;
        }
        div {
            color: #da1e28;
            padding: 0px 8px;
            background-color: #fff1f1;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 12px;
            border-radius: 3px;
        }
    }
    .titleSection {
        gap: 17px;
        display: flex;
        align-items: center;
        .override_btn {
            position: static;
            padding: 8px 12px 7px 12px;
            background-color: #f9fcff;
            border-radius: 30px;
            font-size: 12px;
            font-family: 'SF-Pro-Display-Medium';
            margin: auto 0 auto auto;
            color: #004aad;
            border: 1px solid #004aad;
            cursor: pointer;
        }
        .title {
            display: flex;
            align-items: center;
            gap: 8px;
            h3 {
                color: #233862;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                letter-spacing: 0.78px;
                text-transform: uppercase;
                margin: auto;
            }
        }
        .tag {
            padding: 0px 8px;
            display: flex;
            justify-items: center;
            align-items: center;

            font-family: 'SF-Pro-Display-Semibold';
            font-size: 12px;
            border-radius: 3px;
        }
        .tag.success {
            color: #32cc79;
            background-color: #32cc7926;
        }
        .tag.failure {
            color: #da1e28;
            background-color: #fff1f1;
        }
    }

    .identityMetaData {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        div {
            h4 {
                color: #3d5075;
                font-family: 'SF-Pro-Display';
                font-size: 10px;
                font-style: normal;
                line-height: 14px;
            }
            p {
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
            .imageAnalyzed {
                cursor: pointer;
                display: flex;
                gap: 10px;
                align-items: center;
                p {
                    text-align: center;
                    font-family: 'SF-Pro-Display-Semibold';
                    font-size: 12px;
                    line-height: normal;
                    color: #004aad;
                }
            }
        }
    }
    .checkContainer {
        display: flex;
        gap: 3px;
        img {
            width: 19px;
        }
    }
}
.image_comparison {
    display: flex;
    justify-content: center;
    padding: 50px;
    gap: 20px;
    @media screen and (max-width: 600px) {
        padding: 20px 15px;
    }
    .photo_container {
        padding: 15px;
        background: #f9fcff;
        border-radius: 14px;
        img {
            height: 145px;
            border-radius: 25px;
        }
        p {
            text-align: center;
            font-size: 11px;
            color: #004aad;
            font-weight: 600;
            margin-top: 12px;
        }
    }
}
.override_analysis_container {
    padding: 30px 50px;

    @media screen and (max-width: 600px) {
        padding: 20px 15px;
    }
    .failed_properties_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f9fcff;
        border: 1px solid #e2eeff;
        padding: 10px 15px;
        border-radius: 16px;
        p.failed_props {
            color: #3d5075;
            span.number {
                color: #004aad;
                margin-right: 5px;
                font-size: 16px;
            }
        }
    }
    .property_details_container {
        background-color: #f9fcff;
        border: 1px solid #e2eeff;
        padding: 10px 15px;
        border-radius: 16px;
        margin-top: 20px;
        .heading {
            display: flex;
            border-bottom: 1px solid #e2eeff;
            padding-bottom: 6px;
        }
        .flex {
            margin-top: 10px;
            display: flex;
        }
        .property {
            width: 130px;
        }
        .profile {
            width: 100px;
        }
        .bvn {
            width: 70px;
        }
        .status {
            width: 50px;
            img {
                width: 19px;
            }
        }
    }
}
.override_all_btn {
    padding: 7px 12px;
    background-color: #f9fcff;
    border-radius: 30px;
    font-size: 12px;
    font-family: 'SF-Pro-Display-Medium';
    color: #004aad;
    border: 1px solid #004aad;
    cursor: pointer;
}
.image_section {
    display: flex;
    flex-direction: column;
    border: 1px solid #e2eeff;
    padding: 10px 15px;
    border-radius: 16px;
    margin-top: 20px;
    .pictures {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .picture_wrapper {
            display: flex;
            align-items: center;
            padding: 10px;
            background-color: #f9fcff;
            border-radius: 16px;
            width: 48%;
            gap: 6px;
            img {
                height: 22px;
                border-radius: 4px;
            }
            p {
                font-size: 12px;
                color: #004aad;
                font-weight: 600;
            }
        }
    }
}
