.Wrapper {
    padding: 24px 42px;

    >div:first-of-type {
        margin: 24px 0 34px 0;
        padding: 24px;
        text-align: center;
        border-radius: 15px;
        background: var(--shades-and-tints-primary-f-9-fcff, #F9FCFF);

        >p {
            color: #000;
            font-family: "SF-Pro-Display-Bold";
            font-size: 18px;
            font-style: normal;
            line-height: 24px;
            margin-top: 4px;
        }

        >h3 {
            color: var(--accent-text-tertairy-88, rgba(35, 56, 98, 0.88));
            font-family: "SF-Pro-Display-Medium";
            font-size: 12px;
            font-style: normal;
            line-height: 24px;
            letter-spacing: 0.78px;
        }
    }

    >p {
        color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
        font-family: "SF-Pro-Display";
        font-size: 14px;
        font-style: normal;
        line-height: 150%;
        margin-bottom: 16px;
    }

    >div:last-child {
        display: flex;
        flex-direction: column;
        gap: 34px;
        margin-bottom: 24px;

        >div {
            display: flex;
            justify-content: space-between;

            >div {
                >div {
                    display: flex;
                    gap: 4px;

                    >div {

                        >div {
                            position: absolute;

                            >svg {
                                position: relative;
                                top: 2px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                h2 {
                    color: var(--accent-text-tertairy-88, rgba(35, 56, 98, 0.88));
                    font-family: "SF-Pro-Display-Medium";
                    font-size: 12px;
                    font-style: normal;
                    line-height: 24px;
                    letter-spacing: 0.78px;
                    margin-bottom: 0;
                }


                >p {
                    color: var(--Accent-Text-Primary, #011A3C);
                    font-family: "SF-Pro-Display-Bold";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 120%;
                }
            }

        }
    }
}