.errorHandlerContainer {
    z-index: 1;
    background-color: #001837;
    position: absolute;
    padding: 16px;
    border-radius: 15px;
    width: 330px;
    .imgWrapper {
        position: relative;
        img.errorTip {
            position: absolute;
        }
    }
    p.errorText {
        color: #fff;
    }
    .action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        button {
            cursor: pointer;
            background-color: #004aad;
            color: #fff;
            border: 1px solid #004aad;
            border-radius: 30px;
            padding: 2px 12px;
            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
        p {
            color: #ffffffc7;
        }
    }
}
