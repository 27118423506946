.cssePromoCarouselSectionWrapper {
    margin-bottom: 100px;
    width: 100%;

    .slick-list {
        height: 100%;
        max-height: 290px;

        @media screen and (max-width: 500px) {
            height: auto;
            max-height: 180px;
        }
    }
}

.cssePromoCarouselWrapper {
    display: flex;
    justify-content: center;

    > div {
        width: 100%;
        max-width: 1244px;
    }

    & {
        padding: 45px 20px;
        width: 100%;
    }

    @media screen and (min-width: 1500px) {
        padding: 45px 120px;
    }

    @media screen and (max-width: 500px) {
        margin-bottom: 0;
    }
}

.cssePromoHeaderWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 30px 0px;

    div {
        max-width: 608px;
    }

    @media screen and (max-width: 500px) {
        margin-bottom: 0px;
    }
}

.cssePromoCarouselHeader {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 35px;
    line-height: 45px;
    color: #233862;
    margin-bottom: 5px;

    @media screen and (max-width: 500px) {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 32px;
        line-height: 38px;
    }
}

.cssePromoCarouselParagraph {
    color: #5f5b5b;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 23px;
    }
}

.cssepromoCarouselImg {
    width: 100%;
    height: 255px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 28px;

    @media screen and (max-width: 500px) {
        height: 160px;
        object-fit: contain;
    }
}

.cssepromoOneImg {
    height: 238px;
    max-width: 100%;
    cursor: pointer;
}

.slick-slider:hover {
    .slick-prev {
        display: block;
    }

    .slick-next {
        display: block;
    }
}

.slick-prev {
    left: 10px;
    top: 43%;
    display: none;
}

.slick-next {
    right: 42px;
    top: 43%;
    display: none;
}

.slick-prev:before {
    content: '';
}

.slick-next:before {
    content: '';
}

.SavingsCalculatorSection {
    width: 628px;
    padding: 24px;
    border-radius: 48px;
    border: 1px solid #e2eeff;
    background: #fff;
    height: 950px;
    margin-top: 20px;

    .ant-drawer-mask {
        background: rgba(238, 238, 238, 0.21);
        backdrop-filter: blur(5px);
        border-radius: 48px;
    }

    .ant-drawer {
        z-index: 999;
        border-radius: 48px;
    }

    .ant-drawer-content {
        border-bottom-left-radius: 48px;
        border-bottom-right-radius: 48px;
    }

    .ant-drawer-content-wrapper {
        border-bottom-left-radius: 48px;
        border-bottom-right-radius: 48px;
    }

    &.OnSolarCalculator {
        @media only screen and (max-width: 800px) {
            width: 100%;
            height: auto;
        }
    }

    @media only screen and (max-width: 768px) {
        .ant-drawer-content {
            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;
        }
    }
}

.cssePromoCarouselCard {
    width: 100%;
    padding: 0 19px;
}

.slick-slide {
    width: 620px;

    @media screen and (max-width: 768px) {
        max-width: none;
        width: 100%;
        min-width: none;
    }

    @media screen and (max-width: 320px) {
        max-width: 280px;
        width: 100%;
        min-width: 250px;
    }
}
