.pageWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
}

.pageContentWrapper {
    flex: 1;
    display: grid;
    place-items: center;
    gap: 0;
}

.pageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 724px;
    text-align: center;

    > h1 {
        font: 18px/27px var(--font-regular);
        margin: 0 0 8px 0;
        color: #4d4d4d;
    }

    > p {
        font: 14px/21px var(--font-regular);
        margin: 0;
        color: #4d4d4d;
    }

    @media only screen and (max-width: 800px) {
        padding: 0 20px;
    }
}

.requestDeclinedImg {
    width: 195px;
    height: 195px;
    object-fit: contain;
}
