.HeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.Title {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 22px;
    line-height: 27px;
    color: var(--primary-blue);
    margin-bottom: 5px;

    @media screen and (max-width: 500px) {
        font-size: 15px;
    }
}

.TitleM20 {
    font: 22px/26.25px var(--font-bold);
    color: var(--primary-blue);

    @media screen and (max-width: 500px) {
        font: 20px/23.87px var(--font-bold);
    }
}

.SubTitle {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.8);
}

.SubTitleM20 {
    font: 15px/26px var(--font-regular);
    color: rgba(35, 56, 98, 0.8);

    @media screen and (max-width: 500px) {
        font: 14px/21px var(--font-regular);
    }
}
