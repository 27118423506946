@mixin reset {
    margin: 0;
    padding: 0;
}

.ACLWrapper {
    width: 100%;
    padding: 24px 100px;
    position: relative;
    color: #011a3c;
    font-family: 'SF-Pro-Display';

    @media screen and (min-width: 1400px) {
        padding-left: 112px;
    }

    @media screen and (max-width: 1188px) {
        padding: 10px;
    }
}

.ACLTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;
    margin-bottom: 50px;
    margin-top: -54px;

    @media screen and (max-width: 768px) {
        margin-top: 10px !important;
    }

    @media screen and (max-width: 500px) {
        margin-top: 10px !important;
    }
}

.ACLTitleComponents {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ACLTitleComponentsButtonWrapper {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .ACLTitleComponentsSvgButton {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: #e2eeff;
        border-radius: 20px;

        & > rect {
            fill: #e2eeff;
            line-height: 100%;
        }
    }

    .ACLTitleComponentsLink {
        display: flex;
        align-items: center;
    }

    @media screen and (min-width: 1188px) {
        display: none;
    }
}

.ACLTitleComponentsButtonOne {
    margin-right: 6px;
}

.ACLTopWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ACLInputWrapper {
    display: flex;
    width: 60%;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.ACLTopButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 9px;

    @media screen and (max-width: 1187px) {
        display: none;
    }
}

.ACLFilterHeader {
    @include reset;
    font-size: 20px;
    font-family: 'SF-Pro-Display-Bold';
    margin-bottom: 35px;
    color: #011a3c;
}

.ACLFilterByDateHeader {
    @include reset;
    font-size: 14px;
    font-family: 'SF-Pro-Display-Semibold';
    margin-bottom: 19px;
    color: #011a3c;
}

.ACLDateFieldsWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;

    @media only screen and (max-width: 375px) {
        gap: 6px;
    }
}

.ACLDateField {
    width: 154px !important;

    @media screen and (max-width: 500px) {
        width: 164px !important;
    }
}

.ACLFilterByInterestHeader {
    @include reset;
    font-size: 14px;
    font-family: 'SF-Pro-Display-Semibold';
    margin-bottom: 19px;
    color: #011a3c;
}

.ACLFilterButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 40px;
}

.ACLFilterModeText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 20px;
    color: #011a3c;
}

.ACLPaginationWrapper {
    text-align: center;
    margin-top: 42px;
}

// table styling
.ACLLargeTable {
    display: block;

    @media screen and (max-width: 800px) {
        display: none;
    }
}
.ACLTableHead {
    width: 100%;
    display: grid;
    grid-template-columns: 27% 20% 15% 25% 13%;
    padding: 20px 0 20px 49px;

    p {
        @include reset;
        color: #011a3ccc;
        font-family: 'SF-Pro-Display';
        font-size: 13px;
    }

    @media screen and (max-width: 1188px) {
        padding-left: 10px;
    }
}

.ACLTableRow {
    width: 100%;
    display: grid;
    grid-template-columns: 27% 20% 15% 25% 13%;
    background-color: #ffffff;
    align-items: center;
    padding: 15px 0 15px 49px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-bottom: 12px;

    p {
        @include reset;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        color: #011a3c;

        &:first-of-type {
            font-family: 'SF-Pro-Display-Semibold';
        }
    }

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    }

    @media screen and (max-width: 1188px) {
        padding-left: 10px;
    }
}

.ACLMobileTable {
    height: 178px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    padding: 24px 21px;
    margin-bottom: 13px;

    @media screen and (min-width: 801px) {
        display: none;
    }
}

.ACLMobileTableSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ACLMobileTableHeader {
    @include reset;
    margin-bottom: 5px;
    font-family: 'SF-Pro-Display-Medium';
    color: #011a3cd9;
    font-size: 8px;
}

.ACLMobileText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    color: #011a3c;
    word-break: break-word;

    &.Name {
        font-size: 15px;
    }

    &.Right {
        text-align: right;
    }
}

.AdminRequestListingFilterBox {
    display: flex;
    gap: 23px;
    height: 45px !important;
    width: 130px;
    margin-left: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 10px !important;
    padding: 11px 20px;
    cursor: pointer;

    @media only screen and (max-width: 320px) {
        width: 100%;
        margin-left: 3px;
        gap: 8px;
    }
}

.ARLTableWrapper {
    margin-top: 40px;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--blue);
        font-family: 'SF-Pro-Display-Bold';
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        height: 3px;
    }
    .ant-tabs-content {
        flex-direction: column;
    }

    @media only screen and (max-width: 500px) {
        .ant-tabs-tab {
            width: 134px;
        }
    }
}

.NoActivityStyle {
    margin-top: 150px;
}
