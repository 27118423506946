.ActionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 450px;
}

.ActionHeader {
    font: 16px/24px 'SF-Pro-Display';
    color: #011a3c;
    margin-bottom: 7px;
    text-align: center;
    max-width: 80%;
    margin-top: 20px;
}

.ActionText {
    font: 12px/20px 'SF-Pro-Display';
    color: #4d4d4d;
    text-align: center;
    max-width: 294px;
}

.ActionWarning {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    font: 12px/20px 'SF-Pro-Display-Light';
    color: #011A3C;
    margin-top: 10px;
    border-radius: 8px;
    background: var(--shades-and-tints-primary-e-2-eeff, #E2EEFF);
    max-width: 351px;
}

.BankDetailsSection {
    padding: 14px 16px;
    border-radius: 14px;
    border: 1px solid #e2eeff;
    background: #fff;
    width: 80%;
    margin-top: 30px;

    p {
        color: #233862;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
    }

    .BankDetailsHeadings {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
    }
}

.BankDetailsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    p {
        word-wrap: break-word;
        width: 105px;
    }
}