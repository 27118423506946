.formWrapper {
    width: 505px;
    padding: 24px;
    background-color: #f9fcff;
    border-radius: 14px;
    height: fit-content;

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
}

.fullName,
.contactInfo,
.whereYouWork,
.employeeData,
.businessDetails,
.pensionDetails {
    > * {
        width: 100%;
    }
}

.fullName,
.whereYouWork,
.employeeData,
.businessDetails,
.pensionDetails {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        gap: 0px;
    }
}

.contactInfo {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;

    @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
        gap: 0px;
    }
}

.sameAddressWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
        font: 14px/21px var(--font-regular);
        margin: 0;
        color: #233862;
    }

    @media only screen and (max-width: 500px) {
        > p {
            font: 12px/18px var(--font-regular);
        }
    }
}

.businessDetailsRowTwo {
    > * {
        width: 100%;
    }

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 0;

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
