.wrapper {
    border-radius: 14px;
    border: 1px solid #e2eeff;
    width: 100%;
    padding: 16px 14px;
    background-color: #ffffff;
    .source {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .third_party {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #32cc79;
        }
        .manual_upload {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #004aad;
        }
    }
    .bank_details {
        display: flex;
        align-items: center;
        gap: 23px;
        margin-top: 15px;
        > div {
            display: flex;
            flex-direction: column;
            h3 {
                font-size: 12px;
                line-height: 20px;
                font-family: 'SF-Pro-Display';
                color: #3d5075;
            }
            p {
                color: #070c18;
                font-size: 16px;
                font-family: 'SF-Pro-Display';
            }
        }
    }
}

.remove {
    width: 13px;
    height: 13px;
    flex-shrink: 0;
}

.bankAnalysisStatusStyle {
    border-radius: 3px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    gap: 2px;

    span {
        font-family: 'SF-Pro-Display-SemiBold';
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1.02px;
        text-transform: uppercase;
    }

    .tooltipWrapper {
        margin-top: 4px;
    }
}

.bank_primary_statement {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 0;

    background: none;
    outline: none;
    border: none;
}

.inReviewCard_Loader {
    display: flex;
    align-items: center;
    gap: 7px;
    height: 29px;
    padding: 0 9px;
    margin: 0;
    font: 14px/24px var(--font-semibold);
    color: #3d5075;
    cursor: not-allowed;
}
