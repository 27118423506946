.ParentWrapper {
    background: #f9fcff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ChildWrapper {
    max-width: 1200px;
    width: 100%;
}

.ParentWrapper_Shared {
    > div:not(:first-child) {
        filter: blur(4px);
    }

    > footer {
        filter: blur(4px);
    }
}

.MainWrapper {
    padding: 53px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 1023px) {
        padding: 96px 20px;
    }
}

.NoMargin > div {
    margin: 0;
}

// Header styles
.HeaderWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px;

    @media (min-width: 767px) {
        margin-bottom: 44px;
    }
}

// Profile card styles
.ProfileCardWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: center;
    gap: 17px;
    border-radius: 16px;
    border: 1px solid var(--shades-and-tints-primary-e-2-eeff, #e2eeff);
    background: var(--shades-and-tints-gray-scale-ffffff, #fff);

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 42px;
    }
}

.PCTextContent {
    width: 100%;

    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    h3 {
        margin: 0 0 8px 0;
        color: var(--shades-and-tints-primary-3-d-5075, #3d5075);
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        font-style: normal;
        line-height: 150%;
    }

    h2 {
        margin: 0 0 16px 0;
        color: var(--shades-and-tints-primary-001837, #001837);
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
    }

    p {
        margin: 0 0 0 0;
        color: var(--shades-and-tints-primary-001837, #001837);
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        font-style: normal;
        line-height: 150%;
    }

    @media (min-width: 767px) {
        h3 {
            font-size: 16px;
        }

        h2 {
            font-size: 18px;
        }

        p {
            font-size: 14px;
        }

        > div > div {
            margin-top: 21px;
        }
    }
}

.PCImageWrapper {
    width: 100%;
    border-radius: 8px;
    background: #004aad;
    height: 148px;
    overflow: hidden;

    > img {
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
        background-size: contain;
    }

    @media (min-width: 767px) {
        max-width: 553px;
        height: 236px;
    }
}

.PCShareWrapper {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Accent-Blue-Primary, #004aad);
    text-align: center;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
}

// Systems styles
.SystemsWrapper {
    width: 100%;
    margin-top: 20px;

    @media (min-width: 767px) {
        margin-top: 38px;
    }
}

.SystemsMainContentWrapper {
    display: flex;
    gap: 16px;
    margin-top: 20px;
    justify-content: space-between;

    > div:first-child {
        display: none;
        height: fit-content;

        @media (min-width: 1023px) {
            display: flex;
            width: 100%;
            max-width: 274px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            border-radius: 16px;
            background: #fff;
            padding: 16px 0;
        }
    }
}

.SystemsListTopStyle {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    align-items: center;

    @media screen and (max-width: 500px) {
        display: block;
    }

    > p:first-child {
        color: #070c18;
    }

    p {
        color: #233862;
    }

    .SystemsSortByWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            padding: 12px;
        }

        .ant-select.SelectBox.ant-select-single.ant-select-show-arrow {
            @media screen and (max-width: 500px) {
                width: 180px !important;
                height: 48px;
            }

            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                @media screen and (max-width: 500px) {
                    width: 171px !important;
                    height: 48px;
                }
            }
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            @media screen and (max-width: 500px) {
                line-height: normal !important;
            }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            @media screen and (max-width: 500px) {
                min-height: auto;
                padding-left: 0px;
            }
        }
    }

    span {
        color: #b0b0b0;
        white-space: nowrap;
    }

    &__btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        @media screen and (max-width: 500px) {
            justify-content: space-between;
        }

        span {
            color: #004aad;
            text-align: center;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 12px;
            font-style: normal;
            line-height: normal;
        }

        p {
            color: var(--shades-and-tints-gray-scale-070-c-18, #070c18);
            text-align: center;
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            font-style: normal;
            line-height: 150%;
        }
    }
}

.SystemsListBottomStyle {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 38px;
    width: 100%;

    @media screen and (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
        margin-top: 50px;
        grid-gap: 30px;
    }

    @media screen and (max-width: 700px) {
        margin-top: 50px;
        grid-gap: 30px;
    }

    .BestFitStyle {
        padding: 0px;
    }
}

.SystemsListBottomStyle > div:first-child {
    border: 1px solid #e2eeff;
}

.SolarSystemListWrapper {
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    padding-top: 20px;
    flex-basis: 274px;
    border: 1px solid #e2eeff;

    @media (min-width: 1023px) and (max-width: 1440px) {
        min-width: 250px;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
    }

    @media screen and (max-width: 620px) {
        width: 100%;
        flex-basis: 100%;
        max-width: 300px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 701px) {
        > span:has(img) {
            width: 100%;
            display: block !important;
            padding: 0;
        }

        &.SolarSystemListWrapper_BestFit {
            > span:has(img) {
                padding: 20px 20px 0 20px;
            }
        }

        > span:has(img) img {
            height: 147px;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        margin: 0px auto;
        display: block;
    }
}

.SolarSystemDescriptionWrapper {
    padding: 1px 20px 20px;
    background: #004aad;
    color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .SolarSystemNameStyle {
        color: #ffffff;
    }

    .SolarSystemDescriptionStyle {
        color: #ffffff;
    }

    .SolarSystemPlanWrapper p {
        background: #002c68;
        color: #ffffff;
    }
}

.SolarSystemNameWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    margin: 10px 0px 3px;
}

.SolarSystemNameStyle {
    font-family: 'SF-Pro-Display';
    color: #070c18;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    font-size: 14px;
    line-height: 24px;
}

.SolarSystemFitStyle {
    background: #f9fcff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    height: 18px;

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
        color: #004aad;
        white-space: nowrap;
    }

    @media screen and (max-width: 500px) {
        padding: 5px !important;
    }
}

.SolarSystemDescriptionStyleContainer {
    display: flex;
    justify-content: space-between;
}

.SolarSystemDescriptionStyle {
    font-family: 'SF-Pro-Display-Bold';
    color: #070c18;
}

.SolarSystemAfterCare {
    background-color: #fff8e8;
    padding: 4px;
    display: flex;
    gap: 4px;
    align-items: center;
    border-radius: 4px;
    height: 21px;

    span {
        font-size: 14px;
        line-height: 21px;
        color: #070c18;
    }

    svg {
        color: #ffc222;
        width: 9.822px;
        height: 9.822px;
    }
}

.SolarSystemCardHR {
    border: none;
    border-top: 0.3px solid #b0b0b0;
    margin: 16px 0;
}

.SolarSystemPlanWrapper {
    display: flex;
    margin: 10px 0px;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        background: linear-gradient(
            90deg,
            #f1f1f1 0%,
            #f3f3f3 51.7%,
            #f3f3f3 100%,
            #f1f1f1 100%
        );
        border-radius: 1.488px;
        padding: 0px 5px;
        margin-right: 5px;
        color: #4d4d4d;
    }
}

.SolarSystemPriceWrapper {
    display: flex;
    justify-content: space-between;

    div {
        p:first-child {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 20px;
            color: #b0b0b0;
        }
    }
}

.SolarSytemViewBtn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.SSEEditModalWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 380px;
    font-family: 'SF-Pro-Display';

    @media screen and (max-width: 500px) {
        height: 500px;
    }

    svg {
        @media screen and (max-width: 500px) {
            margin-bottom: 40px;
        }
    }

    h3 {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #011a3c;
    }

    p {
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #4d4d4d;
        width: 294px;
    }
}

.SSEEditApplianceWrapper {
    padding: 0px 20px;

    @media screen and (max-width: 500px) {
        padding: 0;
    }

    h3 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #004aad;
    }

    .SSEEditApplianceSubHeading {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #5f5b5b;
        width: 324px;
    }

    .SelectFloatingLabel {
        top: 15%;
    }
}

// Filter wrappers

.FilterSortWrapper {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
        margin-top: 30px;
        width: 100%;
        gap: 32px;
    }
}

.FilterMobileWrapper {
    display: flex;
    padding: 0 20px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--shades-and-tints-gray-scale-fbc-948, #f1f1f1);
    background: #fff;

    span {
        color: var(--Accent-Blue-Dark, #001837);
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
    }

    @media (min-width: 1023px) {
        display: none;
    }
}

// filter component styles
.FilterWrapper {
    width: 100%;
}

.FilterCardWrapper {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    margin: 0 16px;
    border-radius: 8px;
    background: var(--shades-and-tints-primary-e-2-eeff, #e2eeff);

    h4 {
        color: var(--Accent-Blue-Dark, #001837);
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        margin: 0;
    }

    p {
        color: var(--Accent-Text-Secondary, #233862);
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 150%;

        margin: 0;
    }

    button {
        border: none;
        background: transparent;
        color: var(--primary-004-aad, #004aad);
        text-align: center;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 13px;
        font-style: normal;
        line-height: normal;
        padding: 0;
        margin: 12px 0 0 0;
        cursor: pointer;
    }
}

.FilterTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 16px 16px 16px;

    h2 {
        color: var(--Accent-Blue-Dark, #001837);
        font-family: 'SF-Pro-Display-Bold';
        font-size: 22px;
        font-style: normal;
        line-height: 35px;
        margin: 0;
    }

    button {
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 30px;
        border: 0.3px solid var(--primary-004-aad, #004aad);
        background: var(--shades-and-tints-primary-f-9-fcff, #f9fcff);
        // typography
        color: var(--primary-004-aad, #004aad);
        text-align: center;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        font-style: normal;
        line-height: normal;
    }
}

.FilterOptionsWrapper {
    padding: 24px 16px 16px 16px;
    border-top: 0.3px solid #e5e5e5;

    > div {
        margin-top: 16px;
    }

    .ant-slider {
        margin-bottom: 50px;
    }

    h3 {
        color: var(--accent-text-tertairy-88, rgba(35, 56, 98, 0.88));
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        font-style: normal;
        line-height: 150%;
        margin: 0;
    }
}

.FilterOptionsChildWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    > div {
        flex-wrap: wrap;
        gap: 16px;

        > div {
            width: fit-content;
            padding: 9px 15px !important;
        }
    }
}

// drawer styles
.DrawerCloseIconWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
}

// build custom modal

.BuildCustomModalContentWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
    text-align: center;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        max-width: 345px;
        margin: 40px 0;
    }

    h3 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #011a3c;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 18px;
        color: #4d4d4d;
    }
}

// shared form styles
.SharedFormWrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background: #dcdcdcdb;

    > div {
        display: flex;
        width: 100%;
        max-width: 954px;
        border-radius: 10px;
        border: 1px;
        padding: 0 20px;

        @media (min-width: 860px) {
            display: grid;
            grid-template-columns: 45% 55%;
            position: relative;
            top: -60px;
        }

        > div:first-child {
            background: #fff;
            width: 100%;
            padding: 27px 16px 20px 16px;
            border-radius: 24px;

            @media (min-width: 860px) {
                border-radius: 24px 0px 0px 24px;
                padding: 38px 40px 20px 40px;
            }

            > img {
                position: relative;
                left: -24px;
            }

            > h2 {
                color: var(--shades-and-tints-primary-233862, #233862);
                font-family: 'SF-Pro-Display-Bold';
                font-size: 18px;
                font-style: normal;
                line-height: 24px;
                margin: 0;
                margin-top: 24px;
            }

            > h3 {
                color: #5f5b5b;
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                font-style: normal;
                line-height: 150%;
                margin: 0;
                margin-top: 4px;

                span {
                    font-family: 'SF-Pro-Display-Medium';
                }
            }

            > div:last-child {
                display: flex;
                align-items: baseline;
                justify-content: center;
                flex-wrap: wrap;
                gap: 18.5px;

                @media (min-width: 767px) {
                    justify-content: space-between;
                }

                > div {
                    width: auto;
                }

                > button {
                    margin-top: 0 !important;

                    @media (min-width: 767px) {
                        max-width: 153px;
                    }
                }
            }
        }

        > div:nth-child(2) {
            display: none;

            @media (min-width: 860px) {
                display: flex;
                background: #004aad;
                width: 100%;
                max-width: 500px;
                border-radius: 0px 24px 24px 0px;
                overflow: hidden;

                > img {
                    width: 550px;
                    height: auto;
                    position: relative;
                    left: -15px;
                    object-fit: none;
                }
            }
        }
    }
}

.SharedFormWrapper_Returning {
    > div {
        @media (min-width: 860px) {
            grid-template-columns: 55% 45%;

            > div:first-child {
                padding-right: 106px;
            }
        }
        @media (min-width: 1440px) {
            max-width: 1152px;
        }
    }
}

.ClosedClusterWrapper {
    > div:first-child {
        max-width: 1152px;
    }

    > div {
        > div:first-child {
            > img {
                left: 0px;
                width: 200px;
                height: auto;
                max-height: 58px;
                object-fit: cover;
            }

            > h2 {
                font-size: 22px;
                line-height: 35px;
            }

            > h3 {
                font-size: 16px;
            }

            > div:last-child {
                @media (min-width: 767px) {
                    justify-content: flex-start;
                }
            }
        }
    }

    > div > div {
        > div:last-child {
            margin-top: 29px;
        }
    }

    .ClosedClusterEmailWrapper {
        padding-top: 32px;

        p:first-child {
            color: #011a3c;
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            font-style: normal;
            line-height: 150%;
        }

        p:nth-child(2) {
            color: #4d4d4d;
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            font-style: normal;
            line-height: 150%;
            padding-bottom: 12px;
        }
    }
}

.DemandInfoWrapper {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
        font: 14px/21px 'SF-Pro-Display' !important;
        color: #070c18 !important;
    }
}

.ClusterProfileCardWrapper {
    @media (min-width: 768px) {
        flex-direction: row-reverse;
    }

    .PCTextContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        position: relative;

        img {
            max-width: 350px;
            max-height: 75px;
            object-fit: contain;
            margin-bottom: 17px;
        }

        .ChatWrapper {
            margin-top: 16px;
            position: absolute;
            bottom: 20px;

            @media (max-width: 768px) {
                position: static;
            }
        }
    }

    .ClusterPararagraph {
        max-width: 457px;

        p {
            color: #3d5075;
        }
    }
}

.modalSignInBtn {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;

    p {
        font: 14px/21px var(--font-regular);
        margin: 0;
        color: #001837;
    }

    span {
        font: 12px/14.32px var(--font-semibold);
        margin: 0;
        color: #004aad;
        text-decoration: underline;
        text-underline-offset: 2px;
    }
}

.RightSection {
    position: relative;
    width: 100%;
}

.RightBgImg {
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
}
