.GYOEnergySummaryBodyVisible {
    opacity: 1;
    visibility: visible;
    height: 90px;
    margin-top: 22px;
    transition: ease 0.5s;
}

.GYOEnergySummaryBody {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: ease 0.5s;
}

.GYOEnergySummaryArrowRotateHidden {
    rotate: 0deg;
    transition: ease 0.5s;
}

.GYOEnergySummaryBodyVisible {
    opacity: 1;
    visibility: visible;
    height: 90px;
    margin-top: 22px;
    transition: ease 0.5s;
}

.GYOEnergySummaryArrowRotateVisible {
    rotate: 180deg;
    transition: ease 0.5s;
}

.GYOEnergySummaryWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @media screen and (max-width: 500px) {
        margin: 0 10px 30px 10px;
    }

    @media screen and (max-width: 375px) {
        margin: 0;
        justify-content: flex-start;
    }
}

.GYOEnergySummaryChild {
    width: 466px;
    background: #e2eeff;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
}

.GYOEnergySummaryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #004aad;
    }

    > div {
        display: flex;
        align-items: center;
    }
}

.GYOEnergySummaryEnergyCount {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    background: #004aad;
    border-radius: 50%;
    color: #ffffff;
    font-size: 10px;
    line-height: 12px;
}

.GYOEnergySummaryCollapse {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.GYOEnergySummaryEnergyCountLabel {
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: rgba(35, 56, 98, 0.78);
    margin: 0 12px 0 4px;
}

.GYOEnergySummaryBody {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: ease 0.5s;
}

.GYOEnergySummaryTotalWattsLabel {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;

    @media only screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 18px;
    }
}

.GYOEnergySummaryBodyUsageLabel {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 14px;
    color: rgba(35, 56, 98, 0.78);
    width: 101px;

    svg {
        margin-right: 4px;
    }
}

p.GYOEnergySummaryBodyUsageLabel {
    width: 138px;
}

.GYOEnergySummaryBodyUsageValue {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 14px;
    color: #070c18;
}

.GYOEnergySummaryTotalWattsValue {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 24px;
    line-height: 29px;
    color: #011a3c;

    sup {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 10px;
        line-height: 12px;
        color: #011a3c;
        position: relative;
        top: -12px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 22px;
        line-height: 35px;
    }
}

.GYOEnergySummaryDivider {
    border-top: 0.3px solid rgba(35, 56, 98, 0.88);
    margin: 18px 0 10px 0;
}
