.ContentWrapper {
    font-family: 'SF-Pro-Display';
    margin: 50px 30px;
    margin-top: 10px;

    @media screen and (max-width: 900px) {
        overflow-y: visible;
        margin: 10px;
    }

    &.ContentWrapperNoPadding {
        @media screen and (max-width: 900px) {
            margin: 0;
        }
    }

    @media only screen and (max-width: 500px) {
        margin: 30px 20px;
    }
}

.SSEContentWrapper {
    font-family: 'SF-Pro-Display';
    margin-top: 30px;
    @media screen and (max-width: 500px) {
        margin-top: 10px;
    }
}
.EnergyWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.HeaderWrapper {
    margin-bottom: 10px;
}

.Header {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    text-align: center;
}

.SubHeader {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: rgba(35, 56, 98, 0.7);
}

.BtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 45px;
    position: absolute;
    width: 100%;
    gap: 19px;
}

.CloseIconWrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.InputsWrapper {
    display: flex;
    margin-left: 45px;
    margin-bottom: 5px;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 500px) {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media screen and (max-width: 350px) {
        margin-left: 1px;
    }
}

.InputSection {
    margin-top: 20px;
    margin-bottom: 35px;
    max-height: 300px;
    height: 100%;
    overflow-y: auto;
    max-width: 100%;

    @media screen and (max-width: 900px) {
        max-height: 60vh;
        overflow-y: auto;
        width: 92vw;
        padding: 0px 30px 0px 0px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        max-height: 60vh;
        padding: 0px 5px;
        margin-top: 0px;
        padding-bottom: 100px;
    }
    &.InputSectionNoPadding {
        @media screen and (max-width: 900px) {
            padding: 0;
        }
    }
    @media screen and (max-width: 375px) {
        padding: 1px;
    }
}

.AddButtonWrapper {
    display: flex;
    justify-content: flex-end;
    width: 91%;
    margin-top: 30px;

    @media screen and (max-width: 900px) {
        margin-top: 30px;
        width: 100%;
    }
}

.AddButton {
    width: 141px;
    height: 40px;
    background: #e2eeff;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #004aad;
    border: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.SSEAddButtonWrapper {
    display: flex;
    justify-content: flex-end;
    width: 94%;
    margin-top: 30px;
    margin-bottom: 20px;

    @media screen and (max-width: 900px) {
        margin-top: 30px;
        width: 100%;
    }
    @media screen and (max-width: 500px) {
        padding: 0px 10px;
        margin-bottom: 100px;
    }
}
.RemoveApplianceStyle {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    width: 89%;

    @media only screen and (max-width: 500px) {
        width: 95%;
    }
}

.RemoveApplianceText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 11px;
    line-height: 13px;
    color: #004aad;
    padding-left: 5px;
}

.UpdateApplianceName {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    margin-left: 45px;
    margin-bottom: 10px;

    @media screen and (max-width: 500px) {
        margin-left: 10px;
    }
}
.ConsumerSSEHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.3px solid #b0b0b0;
    height: 63px;
    align-items: center;

    p {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        @media screen and (max-width: 500px) {
            margin-left: 30px;
        }
    }

    img {
        padding: 10px;
        cursor: pointer;
    }
}

.SSEEnergySummaryChild {
    width: 561px;
    background: #f9fcff;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    @media screen and (max-width: 500px) {
        width: 333px;
        padding: 10px;
    }
}

.ConsumerSSEFooter {
    position: absolute;
    bottom: 6px;
    width: 100%;
    border-top: 0.3px solid #b0b0b0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 35px;
    @media only screen and (max-width: 500px) {
        position: fixed;
        bottom: 0;
        background: #ffffff;
        padding: 20px 5px;
        z-index: 10;
        gap: 2px;
    }
}
.SSEApplianceTopWrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 45px 10px;
    @media only screen and (max-width: 500px) {
        margin: 15px 10px 10px;
    }
}

.SSEUpdateApplianceName {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    color: #011a3c;
    @media only screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.SSERemoveApplianceWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.SSERemoveApplianceText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #004aad;
    @media only screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.SSECloseIcon {
    margin-right: 6px;
}
.SSEConsumerAddIcon {
    fill: #004aad;
    stroke: #004aad;

    &:hover,
    &:active,
    &:focus {
        fill: #ffffff;
        stroke: #ffffff;
    }
}
