.wrapper {
    padding: 42px 33px 33px 33px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media only screen and (max-width: 500px) {
        padding: 42px 23px 23px 23px;
    }
}

.methodText {
    h4 {
        font: 15px/26px var(--font-medium);
        color: #233862;
        margin-bottom: 3px;
    }

    p {
        font: 12px/20px var(--font-regular);
        color: #233862b2;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        h4 {
            font: 14px/21px var(--font-regular);
        }

        p {
            font: 12px/14.32px var(--font-regular);
        }
    }
}

.btn {
    width: 100%;
    display: flex;
    justify-content: center;
}
