.ModalContentBox {
    text-align: center;
    padding: 30px;

    @media screen and (max-width: 374px) {
        padding: 0px 30px 30px;
    }
}

.SuccessModalContentBox {
    text-align: center;
    padding: 0 30px;

    @media screen and (max-width: 374px) {
        padding: 0px 30px 30px;
    }
}

.OnboardingWrapperStyle {
    padding: 40px 80px 0px;

    @media screen and (max-width: 600px) {
        min-height: 100vh;
        padding: 20px 40px;
        position: relative;
    }

    @media screen and (max-width: 417px) {
        padding: 20px 30px;
    }

    &.ComponentBrandsWrapper {
        padding: 40px 0 0 80px !important;

        @media screen and (max-width: 500px) {
            padding: 40px 0 0 20px !important;
        }
    }
}

.OnboardingWrapperLogo {
    display: none;

    @media screen and (max-width: 600px) {
        padding-top: 30px;
        padding-bottom: 50px;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 374px) {
        padding-bottom: 32px;
    }
}

.WelcomeBoxTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    color: #011a3c;
    margin-top: 20px;
    text-align: center;
    @media screen and (max-width: 600px) {
        margin-top: 50px;
    }
}

.SuccessBoxImg {
    margin: 0px auto;
    @media screen and (max-width: 600px) {
        margin-top: 50px;
        width: 70%;
    }

    @media screen and (max-width: 374px) {
        margin-top: unset;
    }
}

.WelcomeBoxSubTitle {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: rgba(35, 56, 98, 0.8);
    margin-top: 20px;
    max-height: 180px;
    height: 100%;
    overflow: scroll;
}

.ButtonBox {
    margin-top: 20px;
}

.ActionBox {
    display: flex;
    justify-content: center;
    margin: 80px 0px 20px;
    max-height: 100px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        max-height: max-content;
        margin: 10px 0px 20px;
    }
}

.SuccessBoxTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    color: #011a3c;
    margin-top: 20px;
    text-align: center;
    margin-top: 40px;
}

.SuccessBoxSubTitle {
    width: 385px;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: rgba(35, 56, 98, 0.8);
    margin: 15px auto;
    margin-bottom: 30px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.SuccessButtonBox {
    position: relative;
    margin: 40px 0;
}
