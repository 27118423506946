@mixin skeleton-style {
    background-color: #fafafa;
    height: 57px;
    border-radius: 14px;
    min-width: 0 !important;
}

.OnePageKYC_SectionLoader_MixedLengths {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
        'full1 full1 full1 full1 full1 full1'
        'full2 full2 full2 full2 full2 full2'
        'item3 item3 item4 item4 item5 item5'
        'item6 item6 item6 item7 item7 item7'
        'item8 item8 item8 item9 item9 item9';
    column-gap: 10px;
    row-gap: 16px;
}

.OnePageKYC_SectionLoader_MixedLengths > div {
    width: 100%;

    > div {
        width: 100%;
    }

    .ant-skeleton-input {
        @include skeleton-style();
    }
}

.OnePageKYC_SectionLoader_EqualLengths {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 16px;

    > div {
        width: 100%;

        > div {
            width: 100%;
        }

        .ant-skeleton-input {
            @include skeleton-style();
        }
    }
}

.OnePageKYC_SelectedSolutionLoader {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 10px;
    row-gap: 16px;
    margin: 24px 0;

    > div {
        width: 100%;

        > div {
            width: 100%;
        }

        .ant-skeleton-input {
            @include skeleton-style();
        }
    }
}

.KYC_RetrievingStatementLoader {
    display: flex;
    align-items: center;
    gap: 7px;
    height: 44px;
    padding: 0 9px;
    margin: 8px 0;
    border-radius: 8px;
    background-color: #f9fcff;
    font: 14px/24px var(--font-semibold);
    color: #3d5075;
    cursor: not-allowed;
}

.OnePageKYC_SingleInputLoader {
    width: 100%;

    > .ant-skeleton {
        width: 100%;
    }
    .ant-skeleton-input {
        @include skeleton-style();
    }
}
