.thirdparty {
    background-color: #e2eeff;
    border-radius: 14px;
    padding: 14px 16px 14px 16px;

    p {
        font-size: 14px;
        font-family: 'SF-Pro-Display-Light';
        line-height: 24px;
        color: #011a3c;

        span {
            font-size: 14px;
            font-family: 'SF-Pro-Display';
            line-height: 24px;
            color: #011a3c;
        }
    }

    .button_group {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;

        button {
            cursor: pointer;
            background-color: transparent;
            border: 0.5px solid #004aad;
            padding: 7px 9px 7px 9px;
            font-size: 14px;
            font-family: 'SF-Pro-Display-Semibold';
            display: flex;
            align-items: center;
            gap: 7px;
            border-radius: 8px;

            &:hover {
                background-color: #ffffff;
            }

            &:disabled {
                cursor: not-allowed;
            }

            @media screen and (max-width: 480px) {
                padding: 5px;
            }
        }
    }

    .note {
        border-radius: 8px;
        border: 1px solid var(--shades-and-tints-gray-scale-fafafa, #fafafa);
        background: var(--shades-and-tints-gray-scale-ffffff, #fff);
        margin-top: 12px;
        padding: 0 8px;
        font-size: 12px;
        font-family: SF-Pro-display;
    }
}

.bank_statement {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 8px;

    p {
        font-size: 10px;
        font-family: 'SF-Pro-Display';
        line-height: 14px;
    }

    button {
        background-color: transparent;
        border: none;
        color: #004aad;
        padding: 0;
        font-size: 12px;
        font-family: 'SF-Pro-Display-Semibold';
        cursor: pointer;
        text-align: left;
    }

    button:disabled {
        cursor: not-allowed;
    }
}

.audited_report {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    border-radius: 15px;
    margin-bottom: 32px;
    height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon_button {
        border-radius: 15px 0 0 15px;
        border: 1px solid #e2eeff;
        background-color: #f9fcff;
        height: 100%;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon_text {
        display: flex;
        width: 100%;
        align-items: center;
        height: 100%;
        padding: 21px;
    }
}

.bank_statement_modal_body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        text-align: center;

        img {
            margin: 0 auto;
        }

        .supported_format {
            font-size: 13px;
            font-family: 'SF-Pro-Display';
        }

        .upload {
            font-size: 14px;
            font-family: 'SF-Pro-Display';
        }

        p > span {
            color: #004aad;
            text-decoration: underline;
            font-family: 'SF-Pro-Display';
            cursor: pointer;
        }
    }
}

.modal_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px;

    .password_section {
        border-radius: 14px;
        background-color: #fafafa;
        padding: 12px;

        .file_preview {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            background-color: #ffffff;
            border-radius: 14px;
        }

        .file_section_preview {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 11px;
        }

        .file_section {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            gap: 4px;

            .file_name {
                display: flex;
                align-items: center;

                img {
                    transform: rotate(90deg);
                }

                p {
                    color: #233862;
                    font-size: 12px;
                    font-family: 'SF-Pro-Display-Medium';
                    line-height: 24px;
                }
            }

            .file_datestamp > p {
                text-align: right;
                color: #233862;
                font-size: 10px;
                font-family: 'SF-Pro-Display';
                line-height: 22px;
            }
        }
    }
}

.preferred_payment_label {
    margin-top: 25px;
}

.uploaded_bank_statements {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .bank_statement {
        margin-top: 0px;
    }
}

.errorMessage {
    margin-top: -30px;
    font-size: 10px;
    color: #da1e28;
}

.bvn__button {
    margin-bottom: 28px !important;
}

.bvn__field {
    display: flex;
    flex-direction: column;
    position: relative;

    button {
        position: absolute;
        top: 15px;
        right: 24px;
        margin-left: auto;
        display: flex;
        padding: 3px 5px;
        margin-bottom: 8px;
        justify-content: center;
        align-items: center;

        color: #004aad;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-family: 'SF-Pro-Display-Semibold';
        line-height: normal;
        cursor: pointer;

        border-radius: 30px;
        border: 0.3px solid #004aad;
        background: #f9fcff;
        font-family: 'SF-Pro-Display-Medium';
        width: 106px;
        height: 29px;

        span {
            padding-bottom: 1px;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.7;
            border: none;
        }

        @media screen and (max-width: 500px) {
            width: 54px;
        }
    }

    .bvn__verification__inProgress {
        color: #233862;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.32px;
        padding-left: 20px;
    }
}

.bvn__clarity {
    margin-bottom: 32px;
    padding: 14px 16px;
    border-radius: 14px;
    background-color: #e2eeff;
    font-size: 12px;
    font-style: normal;
    font-family: 'SF-Pro-Display';
    line-height: 20px;
}

.bank_statement_error {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin-bottom: 8px;
    margin-left: 20px;
}

.bankStatementWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;

    h3 {
        margin: 0;
        color: #011a3c;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    }
}

.bankActInfo {
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        border: 1px solid #e2eeff;
        background: #fff;
        padding: 14px 16px;

        border-radius: 14px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;

        &--btns {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 23px;

            .pdfBtn {
                display: flex;
                align-items: center;
                gap: 5px;

                svg {
                    height: 16px;
                    width: 16px;
                }

                span {
                    color: #004aad;
                    font-family: 'SF-Pro-Display';
                    font-size: 14px;
                    font-style: normal;
                    line-height: 24px;
                }
            }

            .viewBtn,
            .viewBtn:hover,
            .viewBtn:focus,
            .viewBtn:active {
                display: flex;
                padding: 8px 12px 7px 12px;
                justify-content: center;
                align-items: center;
                gap: 6px;

                background: #004aad;
                border-radius: 30px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;

                color: #fff;
                text-align: center;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                font-style: normal;
                line-height: normal;

                &:hover {
                    color: #fff !important;
                }
            }
        }

        &--items {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            max-width: 100%;

            div {
                max-width: 33.33%;
            }

            h6 {
                color: #3d5075;
                font-family: 'SF-Pro-Display';
                font-size: 12px;
                font-style: normal;
                line-height: 20px;
            }

            p {
                color: #070c18;
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.BankAnalysisModalStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 370px;
    text-align: center;

    h2 {
        color: #011a3c;
        font-size: 16px;
        font-family: 'SF-Pro-Display';
        line-height: 24px;
    }

    p {
        color: #4d4d4d;
        font-size: 12px;
        font-family: 'SF-Pro-Display';
        line-height: 20px;
        width: 345px;
    }
}

.bankAnalysisStatusStyle {
    border-radius: 3px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    margin-left: 10px;

    span {
        font-family: 'SF-Pro-Display-SemiBold';
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1.02px;
        text-transform: uppercase;
    }

    .tooltipWrapper {
        margin-top: 4px;
    }
}

.emptyStatementHeader {
    font: 14px/24px 'SF-Pro-Display';
    color: #5c5c5c;
    margin: 0;
}

.adminPrimaryStatement {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    background: #e2eeff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;

    &__item {
        color: #004aad;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 1.02px;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.onepageKycClass {
    max-width: 457px;
    padding: 14px 16px;
    border-radius: 14px;
    background: #f9fcff;

    button {
        margin: 8px 0;
        border-radius: 8px;
        border: 0.5px solid #004aad;
        font-size: 14px;
        line-height: 24px;
        padding: 7px 9px;
        font-family: var(--font-semibold);
        background-color: transparent;

        img {
            margin-right: 7px;
            height: 29px;
        }
    }

    p {
        color: #011a3c;
    }

    > p:first-of-type {
        color: #011a3c;
        font-size: 12px;
        line-height: 18px;
        font-family: var(--font-light);

        span {
            font: 12px/18px var(--font-regular) !important;
        }
    }
}

.note {
    width: 100%;
    font: 12px/18px var(--font-regular) !important;
    color: #070c18 !important;
    background-color: #ffffff !important;
    padding: 4px 0 4px 8px;
    margin-top: 3px;
    border-radius: 8px;
}

.group {
    display: flex;
    gap: 15px;

    div {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 0px;
    }
}

.uploadedBtn {
    width: 100%;
    height: 60px;
    background: #e2eeff;
    border: 0.5px dashed rgba(0, 74, 173, 0.79);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 19px;

    @media screen and (max-width: 480px) {
        width: 100%;
    }

    svg:last-child {
        margin-left: auto;
        cursor: pointer;
    }
    a {
        width: 100%;
    }
    p {
        font: 14px/22px 'SF-Pro-Display';
        color: #004aad;
        margin-left: 8px;
        max-width: 70%;
        word-break: break-word;
    }
}
