.CookieWrapper {
    display: none;
    justify-content: center;
}

.CookieContentWrapper {
    bottom: 8px;
    background: #ffffff;
    box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 25px;
    margin: 0 auto;
    position: fixed;
    z-index: 9999;
    @media screen and (max-width: 700px) {
        width: 90%;
    }
    @media (min-width: 600px) and (max-width: 700px) {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media screen and (max-width: 400px) {
        & section > button {
            margin-top: 25px !important;
        }
    }
}

.CookieHeader {
    width: 157px;
    height: 21px;
    font-family: 'SF-Pro-Display-SemiBold';
    font-size: 18px;
    line-height: 21px;
    color: #011a3c;
}

.CookieContent {
    width: 639px;
    height: 50px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 22px;
    color: rgba(1, 26, 60, 0.75);
    margin-bottom: 5px;

    > a {
        color: var(--blue);
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        margin-bottom: 50px;
    }

    @media (min-width: 600px) and (max-width: 700px) {
        margin-bottom: 15px !important;
    }
}
