.BusinessFormWrapper {
    position: relative;
    > div > div {
        margin-top: 24px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        gap: 15px;
    }

    .ant-tabs-tab {
        padding: 10px 0 20px 0 !important;
    }

    .ant-tabs-tab-btn {
        color: #004aad;
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-family: 'SF-Pro-Display';
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border: 0.5px solid #e2eeff;
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        height: 1.5px !important;
    }

    // This is to align the floating label and selectfield value as requested by design
    //TODO: include this on the residential form wrapper as well.
    .SelectFloatingLabel {
        top: 20%;
    }

    .SelectFloatingLabelWrapper
        .ant-select-single.ant-select-show-arrow
        .ant-select-selection-item {
        padding: 18px;
    }

    .CountryCodeBox {
        width: 100px;
    }

    .FloatingInput {
        left: 117px;
        top: 18px;
    }
}

.ContactInfo_DualSelectField-Wrapper {
    display: flex;
    gap: 11px;
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
        display: grid;
    }
}

.VerifyCTA {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 16px;

    > button {
        position: absolute;
        top: 15px;
        right: 24px;
        font-style: normal;
        font-size: 12px;
        line-height: normal;
        color: #004aad;
        cursor: pointer;

        border-radius: 30px;
        border: 0.3px solid #004aad;
        background: #f9fcff;
        font-family: 'SF-Pro-Display-Medium';
        width: 79px;
        height: 29px;

        &:disabled {
            border: none;
            cursor: not-allowed;
            opacity: 0.7;
        }
    }
}

.OptionalTypoGraphy {
    position: relative;
    top: -17px;
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3199999928474426px;
    text-align: left;
    padding-left: 20px;
    color: #233862;
}

.ContactAddress_Confirmation-Wrapper {
    > p {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #233862;
        margin-bottom: 8px;
    }

    > div {
        display: flex;
        gap: 10px;
    }
    button:disabled {
        cursor: not-allowed;
    }

    @media only screen and (max-width: 500px) {
        > p {
            font-size: 12px;
            line-height: 18px;
        }
    }
}

.ContactAddress_Confirmation-CTA {
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 11px 0px;
    gap: 10px;
    width: 163px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 28px;
    cursor: pointer;

    // text
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: #011a3c;

    &:hover {
        background: #f9fcff;
        // border-color: #F9FCFF;
    }
}

.BusinessInfo_CACMOdalContent-Wrapper {
    display: flex;
    justify-content: center;
    margin: 36px 0 63px 0;

    > div {
        width: 100%;
        max-width: 338px;
    }

    @media screen and (max-width: 424px) {
        padding: 27px;
    }
}

.fieldError {
    color: #da1e28;
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3199999928474426px;
    text-align: left;
    padding-left: 20px;
    margin-top: 8px;
}

.formStep__emp_b {
    margin-top: 15px !important;
}

.formStep_b,
.formStep__emp_b {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0px;
    margin-top: 33px;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
        cursor: pointer;

        padding: 8px 12px 7px 12px;
        background-color: #004aad;
        color: #ffffff;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;

        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
    }
}

.BusinessFormPageVideo {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: absolute;
    right: 0;
    z-index: 99;

    p {
        color: #05c95f;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 17.5px;
        margin-left: 5px;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.BusinessFormPageVideoMobile {
    display: none;

    p {
        color: #05c95f;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 17.5px;
        margin-left: 5px;
    }

    @media screen and (max-width: 600px) {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        margin: -15px auto 20px;
    }
}
