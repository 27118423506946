.Estimation_Container {
    padding: 0 110px;

    @media (max-width: 1040px) {
        padding: 0 40px;
    }

    @media (max-width: 800px) {
        padding: 0;
        margin-top: -18px;
    }

    .ant-tabs-nav-wrap {
        @media screen and (max-width: 500px) {
            overflow: hidden !important;
        }
    }

    .ant-tabs-nav-operations {
        display: none !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        padding: 20px 22px;
    }

    .menu {
        padding: 12px;
        border-radius: 15px;
    }

    .menuItem {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #233862 !important;
    }

    .Title {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 23px;
        line-height: 27px;
        color: #011a3c;
        margin-bottom: 50px;
        margin-top: -54px;

        @media screen and (max-width: 500px) {
            font-family: 'SF-Pro-Display-Semibold';
        }
    }

    .Estimation_filterBox {
        display: flex;
        height: 52px;
        width: 130px;
        color: green;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        padding: 16px 20px;
        cursor: pointer;
    }

    .filter {
        margin-left: 22px;
        margin-top: 2px;
        font-family: 'SF-Pro-Display-Medium';
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: rgba(1, 26, 60, 0.8);
    }

    .CreateEstimation_Desktop-btn {
        display: flex;
        width: 40%;
        justify-content: flex-end;

        @media (max-width: 678px) {
            display: none;
        }
    }

    .Estimation_Title_Mobile_Btn_Container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 11px;

        @media only screen and (max-width: 500px) {
            margin-top: 30px;
        }
    }

    .CreateEstimation_Mobile-btn {
        display: none;

        @media (max-width: 678px) {
            display: flex;
            align-items: center;
            gap: 6px;

            > button > svg {
                position: relative;
                top: 2px;
            }
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--blue);
        // padding-right: 25px;
    }

    .ant-tabs-tab-btn {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: rgba(1, 26, 60, 0.8);
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border: 1px solid rgba(229, 229, 229, 0.8);
    }

    th,
    td {
        text-align: left;
        padding: 8px;
    }

    .TableHeader {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: rgba(1, 26, 60, 0.8);
    }

    .pages {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        .ant-pagination-item {
            background-color: #f6f6f6;
        }

        .ant-pagination-next .ant-pagination-item-link {
            background-color: #f6f6f6;
        }

        .ant-pagination-prev .ant-pagination-item-link {
            background-color: #f6f6f6;
        }

        .ant-pagination-item-active {
            background: #004aad;
            border-color: #004aad;
            color: var(--white);
        }

        @media screen and (max-width: 500px) {
            .ant-pagination {
                margin-top: 200px;
            }
        }
    }

    ::-webkit-input-placeholder {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: #233862;
    }

    ::-moz-placeholder {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: #233862;
    }

    :-ms-input-placeholder {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: #233862;
    }

    ::-ms-input-placeholder {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: #233862;
    }

    ::placeholder {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: #233862;
    }

    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:focus::-moz-placeholder {
        color: transparent;
    }

    input:focus:-ms-input-placeholder {
        color: transparent;
    }

    input:focus::-ms-input-placeholder {
        color: transparent;
    }

    input:focus::placeholder {
        color: transparent;
    }

    .floatBox {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }

    .ant-tabs-ink-bar {
        background: var(--blue);
    }

    //card component style
    .card-container {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 32.5% 32.5% 32.5%;
        grid-gap: 1.25%;
        margin-top: 24px;

        @media (max-width: 1123px) {
            grid-template-columns: auto auto;
        }

        @media (max-width: 500px) {
            grid-template-columns: auto;
        }
    }

    // filter css
    .EstimationFilterTitle {
        font-family: 'SF-Pro-Display-Bold';
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: #011a3c;
        margin-bottom: 42px;
        margin-top: 13px;
    }

    .EstimationSearchFilterWrapper {
        display: flex;
        width: 60%;

        @media (max-width: 600px) {
            width: 100%;
        }
    }
}

.EstimationFilterText {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
}

.EstimationFilterStatusText {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #233862;
    margin-left: 22.83px;
}
.PrevButton {
    @media screen and (max-width: 500px) {
        bottom: 0 !important;
        margin-top: 12px;
    }
}
.EstimationFilterTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin-bottom: 40px;
    margin-top: 30px;
}

.StatusLabel {
    display: flex;
    width: fit-content;
    cursor: pointer;
}
.CustomersListingsDateStyle {
    width: 100%;

    .react-datepicker__children-container {
        width: 100%;
    }
}

// customer type styles
.CTWrapper {
    display: flex;
    justify-content: center;
    min-height: 324px;
    overflow: hidden;

    &.UseType {
        margin-top: 38px;
        max-height: 600px;
    }

    @media only screen and (max-width: 500px) {
        &.UseType {
            max-height: 500px;
            margin-right: 0;
            padding-right: 24px;
        }

        & {
            margin: 36px 24px;
            min-height: 0;
            overflow: auto;
            overflow-y: auto;
        }
    }
}

.CTHeadingAndText {
    h3 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
        color: #011a3c;
        margin-bottom: 3px;
    }

    > p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        color: #4d4d4d;
        margin-bottom: 14px;

        span {
            font-family: 'SF-Pro-Display-Medium';
        }
    }

    &:first-child {
        margin-bottom: 33px;
        max-width: 367px;
    }

    &.noBottomMargin {
        margin-bottom: 0;
    }

    &.UseTypeContact {
        margin-bottom: 42px;
    }

    @media only screen and (max-width: 500px) {
        &.UseTypeContact {
            margin-bottom: 0;
        }
    }
}

.CTOTPGroup {
    display: flex;
    align-items: center;
    gap: 12px;

    input {
        width: 47px;
        height: 47px;
        border: 0.8px solid #e5e5e5;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'SF-Pro-Display';
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        text-align: center;

        &:focus {
            border-color: #004aad;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        appearance: none;
        -moz-appearance: textfield;
    }

    @media only screen and (max-width: 500px) {
        input {
            width: 43px;
        }
    }
}

.CTResendOTPText {
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 14px;
    margin-top: 18px;

    span {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        line-height: 14px;
        color: #004aad;
        cursor: pointer;

        &:hover {
            color: #013d8c;
        }
    }
}

.CTResentMsg {
    background: #000f23;
    box-shadow: 0px 47.7922px 167.273px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    margin-bottom: 12px;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        margin: 0 3px 0 12px;
    }
}

.CTRequiredInfo {
    margin: 50px 0 0 60px;
    padding-right: 60px;
    min-height: 324px;
    overflow: auto;

    @media (max-width: 500px) {
        margin: 36px 0 36px 24px;
        padding-right: 24px;
        min-height: 0;
    }
}

.CTRequiredInfoList {
    background: #f9fcff;
    border: 1px solid #e2eeff;
    border-radius: 3px;
    margin: 15px 16px 11px 0;
    padding: 12px 17px;

    h3 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
        color: #004aad;
        margin-bottom: 9.26px;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        padding: 0;

        li {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 20px;
            color: #233862;
        }
    }
}

.CTRequiredInfoDisclaimer {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-right: 16px;

    img {
        width: 11px;
        height: 11px;
        margin-top: 2px;
    }

    p {
        font-family: 'SF-Pro-Display';
        margin: 0;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
    }
}

.CTOTPErrorMsg {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    color: #da1e28;
    margin-top: 5px;
}

.CTEmailSentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 50px 60px 0 60px;
    min-height: 324px;
    overflow: hidden;

    h2 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #011a3c;
        text-align: center;
        margin-top: -54px;
        margin-bottom: 7px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        color: #4d4d4d;
        margin: 0;
        text-align: center;
    }

    @media (max-width: 500px) {
        margin: 36px 24px;
        min-height: 0;
    }
}

.CTEmailSentImg {
    width: 100%;
}

.VerifyWithoutOTPStyle {
    border-radius: 12px;
    background: #f9fcff;
    display: flex;
    height: 44px;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    p {
        color: #233862;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
    }
}

.CTUseTypes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 21px;
    position: relative;

    @media only screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        gap: 26px;
    }
}

.CTUseType {
    padding: 17px 13px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: #ebf3ff !important;
    }

    .CTUseType_Img {
        width: 44.1px;
        height: 42px;
        object-fit: contain;
        flex-shrink: 0;
    }

    > div {
        h4 {
            font: 14px/24px 'SF-Pro-Display-Semibold';
            color: #233862;
            margin-bottom: 0;
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
            color: #4d4d4d;
            margin-bottom: 0;
        }
    }

    .CTUseType_CheckedIcon {
        width: 16px;
        height: 16px;
        object-fit: cover;
        position: absolute;
        right: 14px;
    }

    @media only screen and (max-width: 500px) {
        padding: 14px 8px 15px 19px;
        flex-direction: row;
        align-items: center;
        gap: 19px;

        .CTUseType_Img {
            width: 66px;
            height: 66px;
        }

        > div {
            h4 {
                max-width: 156px;
                line-height: 17.5px;
                margin-bottom: 6px;
            }

            p {
                line-height: 16px;
                color: #233862b2;
            }
        }

        .CTUseType_CheckedIcon {
            top: 10px;
            right: 10px;
        }
    }
}

.CTUseTypes_Err {
    position: absolute;
    font: 12px/16px 'SF-Pro-Display';
    color: #da1e28;
    bottom: -20px;
}

.Estimations_StatusFilter {
    max-height: 180px;
    overflow-y: auto;
}

.CTUseTypes_V2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.CTUseType_V2 {
    display: flex;
    padding: 15px;
    align-items: flex-start;
    gap: 19px;
    border-radius: 14px;
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: #ebf3ff !important;
    }

    .CTUseType_Img {
        width: 44.1px;
        height: 42px;
        object-fit: contain;
        flex-shrink: 0;
    }

    > div {
        h4 {
            font: 14px/24px var(--font-bold);
            color: #233862;
            margin-bottom: 0px;
        }

        p {
            font: 12px/18px var(--font-light);
            color: #4d4d4d;
        }
    }

    .CTUseType_CheckedIcon {
        width: 16px;
        height: 16px;
        object-fit: cover;
        position: absolute;
        right: 14px;
    }

    @media only screen and (max-width: 500px) {
        padding: 14px 8px 15px 19px;
        gap: 19px;

        .CTUseType_Img {
            width: 66px;
            height: 66px;
        }

        > div {
            h4 {
                max-width: 156px;
                line-height: 17.5px;
                margin-bottom: 6px;
            }

            p {
                line-height: 16px;
                color: #233862b2;
            }
        }

        .CTUseType_CheckedIcon {
            top: 10px;
            right: 10px;
        }
    }
}

.CTUseTypes_Err_V2 {
    font: 12px/16px 'SF-Pro-Display';
    color: #da1e28;
    bottom: -20px;
}

.CTInputsWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}
