.dotsLineLoader {
    display: flex;
    gap: 2.27px;
    align-items: center;

    .dotsLineDot {
        width: 3.24px;
        height: 3.24px;
        margin: 0;
        background-color: #004aad;
        border-radius: 50%;
        display: inline-block;
        animation: moveUpDown 1.5s infinite ease-in-out;

        &1 {
            animation-delay: 0s;
        }

        &2 {
            animation-delay: 0.3s;
        }

        &3 {
            animation-delay: 0.6s;
        }
    }
}

@keyframes moveUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2px);
    }
}

.logoLoader {
    position: relative;
    width: 74px;
    height: 108px;
}

.topHalf,
.bottomHalf {
    width: 73.84px;
    height: 73.76px;
}

.topHalfWrapper {
    position: absolute;
}

.topHalf {
    animation: moveTopHalf 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.bottomHalfWrapper {
    position: absolute;
    bottom: 0;
}

.bottomHalf {
    animation: moveBottomHalf 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes moveTopHalf {
    0%,
    40% {
        transform: rotate(-45deg);
        opacity: 1;
        margin-top: -10px;
        margin-left: -10px;
    }

    50% {
        transform: rotate(20deg);
        opacity: 0.75;
        margin-top: 20px;
        margin-left: 0;
    }

    70%,
    100% {
        transform: rotate(45deg);
        opacity: 1;
        margin-top: -8px;
        margin-left: 0;
    }
}
@keyframes moveBottomHalf {
    0%,
    40% {
        transform: rotate(135deg);
        opacity: 1;
        margin-top: 0;
        margin-left: 0;
    }

    50% {
        transform: rotate(200deg);
        opacity: 0.75;
        margin-top: -20px;
        margin-left: 0;
    }

    70%,
    100% {
        transform: rotate(225deg);
        opacity: 1;
        margin-top: 0;
        margin-left: -12px;
    }
}
