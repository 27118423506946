.EditorPageContainer {
    @media screen and (max-width: 600px) {
        position: absolute;
        top: 0;
        padding-top: 30px;
        background: white;
        width: 86%;
    }

    @media screen and (max-width: 416px) {
        top: 0;
        position: absolute;
        padding-top: 30px;
        background: white;
        width: 87%;
    }
}

.HeaderWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

.Header {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-blue);
}

.CloseImg {
    cursor: pointer;
    margin-bottom: 30px;
}

.AvatarEditorAbsolute {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 600px) {
        height: 595px;
        position: relative;
    }

    @media screen and (max-width: 416px) {
        height: 510px;
        position: relative;
    }

    @media screen and (max-width: 375px) {
        height: 452px;
    }
}

.EditorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 448px;
    width: fit-content;
    background-color: var(--black);
    margin: 0px -100%;
    padding-left: 62px;

    @media screen and (max-width: 600px) {
        height: 575px;
        padding-left: unset;
    }

    @media screen and (max-width: 417px) {
        padding-right: 23px;
        width: fit-content !important;
    }

    @media screen and (max-width: 374px) {
        height: 427px;
    }
}

.ZoomWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 175px;
    margin-left: 48px;

    @media screen and (max-width: 600px) {
        margin: 0;
        position: absolute;
        right: 5px;
        bottom: 85px;
    }

    @media screen and (max-width: 400px) {
        right: 0px;
        bottom: 75px;
    }
}

.FlexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Zoom {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border: 0;
    cursor: pointer;
}

.ZoomImg {
    margin: 0px auto;
}

.FooterWrapper {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 600px) {
        display: flex;
        margin-top: 10%;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        padding: 0px 25px;
    }

    @media screen and (max-width: 375px) {
        margin-top: 10%;
        margin-right: 20px;
    }
}

.RotateLargeScreen {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.RotateSmallScreen {
    display: none;

    @media screen and (max-width: 600px) {
        display: block;
        position: absolute;
        bottom: 57px;
        width: 100%;
        left: 0;
    }

    @media screen and (max-width: 417px) {
        display: block;
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0;
    }

    @media screen and (max-width: 374px) {
        bottom: 47px;
        left: -10px;
    }
}

.RotateText {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgba(35, 56, 98, 0.7);
    margin-top: 5px;
    margin-bottom: 0;

    @media screen and (max-width: 600px) {
        color: white;
        margin-top: 10px;
    }
}

.RotateText2 {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgba(35, 56, 98, 0.7);
    margin-top: 5px;
    margin-bottom: 0;
}

.ImageWrapper {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;

    > canvas {
        border-radius: 12px;
        height: 260px !important;
        width: 325px !important;
        object-fit: cover;

        @media screen and (max-width: 576px) {
            height: 260px;
            width: 100%;
        }

        @media screen and (max-width: 320px) {
            width: 300px !important;
        }
    }

    > div:last-child {
        margin: 0px;
    }
}

.FooterWrapper2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    div {
        display: flex;
        flex-direction: row;
        grid-column-gap: 8px;
    }
}
