.InputFieldWrapper {
    position: relative;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-right: 3px;

    @media screen and (max-width: 500px) {
        width: 100%;
        margin-right: 114px !important;
    }

    @media screen and (max-width: 320px) {
        margin-bottom: 0px;
    }
}

.DoubleInputFieldsWrapper {
    position: relative;
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: auto 1px auto;
    flex-direction: column;
    margin-right: 3px;
    border: 1px solid #e5e5e5;
    border-radius: 15px;

    @media screen and (max-width: 500px) {
        width: 100%;
        // margin-right: 114px !important;
        margin-right: 0 !important;
    }

    @media screen and (max-width: 320px) {
        margin-bottom: 0px;
    }

    @-moz-document url-prefix() {
        margin-bottom: 20px;
    }
}

.DoubleInputDivider {
    border-right: 1px solid #e5e5e5;
    margin-top: 20px;
    height: 31px;
}

.sseUseDivider {
    height: 100%;
    margin-top: 0px;
}

.DoubleInputDividerActive {
    border-right: 1px solid #004aad;
}

.DoubleInputErrorMessage {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    color: #da1e28;
}

.LargeWidthStyle {
    @media screen and (min-width: 1900px) {
        width: 240px;
    }
}

.InputFieldWrapper input::-webkit-outer-spin-button,
.InputFieldWrapper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.InputFieldWrapper input[type='number'] {
    -moz-appearance: textfield;
}

input:disabled {
    cursor: not-allowed;
}

input:focus ~ .FloatingLabel,
input:disabled ~ .FloatingLabel,
input:not(:focus):valid ~ .FloatingLabel,
input:-webkit-autofill ~ .FloatingLabel {
    font-size: 11px;
    opacity: 1;
    height: 13px;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 3px;
    color: var(--blue);
    top: 14px;
}

.InputText:placeholder-shown:not(:focus) + .FloatingLabel {
    @media only screen and (max-width: 500px) {
        top: 22px;
        margin: 0;
    }
}

.doubleFloatingLabel2 {
    position: absolute;
    font-size: 11px;
    opacity: 1;
    height: 13px;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 3px;
    color: var(--blue);
    top: 12px;
    right: 75px;
}

.DoubleFloatingLabel {
    position: absolute;
    font-size: 11px;
    opacity: 1;
    height: 13px;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 3px;
    color: var(--blue);
    top: 12px;
    left: 20px;
}

.sseDoubleFloatingLabel {
    left: 22.5px;
}

textarea:focus ~ .FloatingLabelArea,
textarea:disabled ~ .FloatingLabelArea,
textarea:valid ~ .FloatingLabelArea {
    position: absolute;
    margin-bottom: 10px;
    font-size: 11px;
    opacity: 1;
    height: 13px;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 3px;
    color: var(--blue);
    top: 14px !important;
}

input:focus,
textarea:focus {
    outline: none;
    border: 1px solid var(--blue);
}

.InputText {
    height: 60px;
    background: var(--white);
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 30px 22px;
    font-size: 16px;
    line-height: 26px;
    color: #233862;
    font-family: 'SF-Pro-Display-Light';
    padding-bottom: 16px;
    width: 100%;

    @media screen and (max-width: 500px) {
        background: var(--white) !important;
        width: 100% !important;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &::-webkit-calendar-picker-indicator {
        display: none !important;
    }
}

.InputText[list] {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}

.NoFloatingLabelInputText {
    padding: 21px 22px;
}

.InputText::placeholder {
    color: rgba(35, 56, 98, 0.78);
}

.CSSESearchStyle {
    margin: 4px 6px;
}

.CSSESearchStyle::placeholder {
    color: #c4c4c4;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    line-height: 24px;
}

textarea.InputText {
    padding: 25px 22px;

    @media screen and (max-width: 500px) {
        height: 172px !important;
    }
}

.ResponsiveWidth {
    @media screen and (min-width: 1900px) {
        width: calc(150px + 100px) !important;
    }

    @media screen and (min-width: 2500px) {
        width: calc(250px + 100px) !important;
    }

    @media screen and (min-width: 5000px) {
        width: calc(350px + 100px) !important;
    }

    @media screen and (max-width: 991px) {
        width: 200px !important;
    }

    @media screen and (max-width: 767px) {
        width: 150px !important;
    }

    @media screen and (max-width: 500px) {
        width: 217px !important;
    }

    @media screen and (max-width: 320px) {
        width: 100% !important;
    }
}

.FloatingLabel {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    height: 26px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.78);
    left: 24px;
    top: 18px;

    @media screen and (max-width: 320px) {
        left: 10px;
    }

    @media screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 18px;
    }
}

.SelectFloatingLabelWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;

    .ant-select-selection-item:not(:has(div)) {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 17px;
        color: var(--lightblue);
    }
}

.SelectNoFloatingLabelWrapper {
    .InitialOptionTab {
        padding-left: 8px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.SelectFloatingLabel {
    position: absolute;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 13px;
    color: #004aad;
    top: 12%;
    margin-left: 25px;
    z-index: 1;

    @media only screen and (max-width: 500px) {
        margin-left: 16px;
    }
}

.DateTimeFloatingLabel {
    position: absolute;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 13px;
    color: #004aad;
    bottom: 53%;
    margin-left: 25px;
}

.FloatingLabelArea {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    height: 26px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.78);
    left: 24px;
    top: 15px;

    @media screen and (max-width: 320px) {
        left: 10px;
    }
}

.SelectFloatingIcon {
    position: relative;
    // pointer-events: none;
    transition: 0.2s ease all;
    height: 26px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.78);
}

.AddFloatingLabel {
    font-family: 'SF-Pro-Display-Bold';
    position: absolute;
    right: 24px;
    top: 23px;
    color: #233862;
    font-size: 14px;
    line-height: 17px;
}

.NairaFloatingLabel {
    position: absolute;
    left: 24px;
    top: 23px;
    color: #233862;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 14px;
    line-height: 17px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 15px;
    border: 1px solid #e5e5e5;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 12px;
    padding-left: 14px !important;
}

.ant-select-arrow {
    top: 45% !important;
}

.OtpInputBox {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 15px;
    width: 60px;
    height: 60px;
    color: #233862;
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 23px;
    text-align: center;
    margin-right: 5px;
}

.OtpInputBoxFilled {
    border: 1px solid #004aad;
}

.ErrorText {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin-left: 10px;
    margin-top: 7px;
    margin-bottom: 10px;
    display: block;
    width: 100% !important;

    @media screen and (max-width: 500px) {
        margin-top: 2px;
    }
}

.ErrorText.ConsumerDateErrorText {
    margin-top: -15px;
    margin-bottom: 16px !important;
}

.ant-select-multiple .ant-select-selection-placeholder {
    color: #233862;
    opacity: 0.78;
    font-size: 14px;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    line-height: 26px;
    padding-left: 10px;
    text-align: left;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: pointer;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 15px;
    left: 25px;

    @media screen and (max-width: 500px) {
        left: 14px;
    }
}

.ant-select-multiple .ant-select-selection-item {
    padding: 10px 16px;
    height: 36px;
    background: #233862;
    border-radius: 21px;
    border: 0px;
    font-size: 12px;
    color: var(--white);
    font-family: 'SF-Pro-Display-Medium';
    line-height: 14px;
    margin: 5px 6px;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
    color: var(--white);
    margin: 0px 5px;
    font-size: 13px;
    font-family: 'SF-Pro-Display-Medium';
}

.password-icon,
.input-lock-icon {
    position: absolute;
    top: 17px;
    cursor: pointer;
    right: 20px;

    @media screen and (max-width: 991px) {
        top: 15px !important;
    }

    @media screen and (max-width: 768px) {
        top: 15px !important;
    }

    @media screen and (max-width: 500px) {
        right: 20px !important;
        left: auto !important;
    }
}

.leftSearchIcon {
    position: absolute;
    top: 17px;
    cursor: pointer;
    right: 20px;

    @media screen and (max-width: 991px) {
        top: 15px;
    }

    @media screen and (max-width: 500px) {
        top: 16px;
    }
}

.SelectBox {
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    position: relative;
    height: 59px;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    line-height: 26px;
    padding-left: 5px;
    color: rgba(35, 56, 98, 0.78);

    div {
        position: relative;
        top: 0.5px;
        display: inline-block;
        border: none;
        margin-left: -5px;
    }

    .ant-select-selection-item {
        svg {
            position: relative;
            display: none;
            top: -10px;
        }

        p {
            margin-top: 5.2px !important;
        }
    }

    &.selectedBlue {
        .ant-select-selection-item {
            div {
                div {
                    p {
                        color: #004aad;
                    }
                }
            }
        }
    }

    .ant-select-arrow {
        top: 45% !important;
        right: 16px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        font-size: 16px !important;
    }

    .ant-select-item-option-content {
        font-family: 'SF-Pro-Display';
        font-size: 16px !important;
        line-height: 26px !important;
        padding-left: 15px !important;
        color: rgba(35, 56, 98, 0.78) !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-left: 0px;
        padding-top: 2px !important;
    }

    svg {
        position: relative;
        top: 3px;
    }

    @media screen and (max-width: 500px) {
        width: 100% !important;
        margin-right: 0px !important;
    }

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

.mobileWidth {
    @media screen and (max-width: 500px) {
        width: 115px !important;
        padding-right: 30px;
    }
}

.DropdownClassName {
    font-family: 'SF-Pro-Display';
    width: 138px !important;
    min-width: 138px !important;

    div {
        font-size: 14px;
        line-height: 17px;
        color: #233862;
    }

    .ant-select-item-option-content {
        padding: 6px;
    }
}

.SavingsDropdownClassName {
    font-family: 'SF-Pro-Display';
    width: 120px !important;
    min-width: 120px !important;

    div {
        font-size: 14px;
        line-height: 24px;
        color: #233862;
    }

    .ant-select-item-option-content {
        padding: 6px;
    }
}

.HalfWidth {
    width: 278px !important;
    min-width: 160px !important;

    .ant-select-item-empty {
        font-family: 'SF-Pro-Display';
        color: rgba(35, 56, 98, 0.8);
        padding: 10px 20px;
    }
}

.analyticsChart {
    height: 40px;
    border-radius: 7px;
}

.analyticsStyle {
    width: 180px !important;
    padding: 18px 0 8px 12px !important;
}

.DateBox {
    display: block;
}

.DateBoxText {
    height: 60px;
    background: var(--white);
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 15px;
    padding-left: 24px;
    padding-top: 16px;
    font-size: 14px;
    line-height: 26px;
    width: 100%;
    position: relative;
    cursor: pointer;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    color: #233862;
    text-align: left;

    @media screen and (min-width: 1900px) {
        width: auto !important;
    }
}

.DateBoxCaret {
    position: absolute;
    left: 90%;
    top: 32%;
}

.CalenderBox {
    z-index: 3;

    .react-datepicker {
        background: #ffffff;
        box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        padding: 10px;
        border: none !important;
        color: #19181a !important;
        width: 320px;
    }

    .react-datepicker-popper {
        z-index: 2;
    }

    .react-datepicker__day--disabled {
        color: #a3a3a3 !important;
        pointer-events: none;
    }

    .react-datepicker__day--outside-month {
        color: #a3a3a3 !important;
        pointer-events: none;
    }

    .react-datepicker__header {
        border: none !important;
        background: none;
    }

    .react-datepicker__day-names {
        margin: 10px 0px -12px !important;
        font-family: 'Inter';
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 6px !important;
    }

    .react-datepicker__triangle {
        display: none !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background: #004aad;
        border-radius: 40px;
        color: #fff !important;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        border-radius: 40px;
        background: #e2eeff;
        color: #004aad !important;
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
        color: #19181a;
        font-style: normal;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background: #e2eeff;
        color: #004aad !important;
        border-radius: 40px;
    }

    .react-datepicker__day-name {
        font-family: 'SF-Pro-Display-Semibold';
    }

    // month dropdown

    .react-datepicker__month-container {
        float: none !important;
    }

    .react-datepicker__month-option {
        background-color: #fff;
        color: #19181a;
        font-style: normal;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 16px;
        border-radius: 20px;
        margin: 5px;
        width: 85%;
        padding: 10px 7px;
    }

    .react-datepicker__month-option:hover {
        background: #ebf3ff !important;
        font-style: normal;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 16px;
        border-radius: 20px;
        margin: 5px;
        width: 85%;
        padding: 10px 7px;
        color: #004aad;
    }

    .react-datepicker__month-option--selected_month {
        background: #004aad !important;
        font-style: normal;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
    }

    .react-datepicker__month-option--selected {
        display: none;
    }

    .react-datepicker__month-dropdown {
        max-height: 400px;
        overflow-y: scroll;
        background-color: #fff !important;
        border: 1px solid #fff !important;
        position: absolute;
        top: 5px;
        left: 50px;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        width: 110px;
        font-family: 'Inter';
    }

    // year dropdown

    .react-datepicker__year-option {
        background-color: #fff;
        color: #19181a;
        font-style: normal;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 16px;
        border-radius: 20px;
        margin: 5px;
        width: 85%;
        padding: 10px 7px;
    }

    .react-datepicker__year-option:hover {
        background: #ebf3ff !important;
        font-style: normal;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 16px;
        border-radius: 20px;
        margin: 5px;
        width: 85%;
        padding: 10px 7px;
        color: #004aad;
    }

    .react-datepicker__year-option:first-child:hover,
    .react-datepicker__year-option:last-child:hover {
        background-color: #fff !important;
    }

    .react-datepicker__year-option--selected_year {
        background: #004aad !important;
        font-style: normal;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 13px;
        line-height: 16px;
        color: #ffffff;
    }

    .react-datepicker__year-option--selected {
        display: none;
    }

    .react-datepicker__year-dropdown {
        max-height: 400px;
        overflow-y: scroll;
        background-color: #fff !important;
        border: 1px solid #fff !important;
        position: absolute;
        top: 5px;
        left: 160px;
        box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        width: 80px;
        font-family: 'Inter';
    }

    a.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
        background-color: #fff !important;
        background-image: url('../../assets/images/arrowDown.svg');
        background-repeat: no-repeat;
        display: block;
        margin: -30px;
        background-size: 15px;
        position: relative;
        top: 9px;
        left: 55%;
        transform: rotate(180deg);
    }

    a.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
        background-color: #fff !important;
        background-image: url('../../assets/images/arrowDown.svg');
        background-repeat: no-repeat;
        display: block;
        margin: -30px;
        background-size: 15px;
        position: relative;
        top: 20px;
        left: 90%;
        transform: rotate(0deg);
    }
}

select.month {
    background: #e2eeff;
    border-radius: 50px;
    padding: 8px 0px 8px 0px;
    margin: 5px;
    border: 1px solid #e2eeff;

    svg {
        display: none !important;
    }
}

select.year {
    background: none;
    border: 1px solid #fff;
    margin: 10px;
    font-style: normal;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 15px;
    line-height: 24px;
    color: #19181a;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    display: none;
}

.react-datepicker__navigation {
    display: none;
}

// month dropdown arrow

.react-datepicker__month-read-view--selected-month {
    background: #e2eeff;
    border-radius: 50px;
    padding: 8px 22px 8px 10px;
    margin: 5px;
    border: 1px solid #e2eeff;
    color: #004aad;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    font-family: 'Inter';
}

.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::after,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow:hover {
    right: 14px !important;
    top: 7px;
    border-color: #004aad;
    height: 7px;
    width: 7px;
    border-width: 2px 2px 0 0;
    border-top-color: #004aad !important;
    color: #004aad;
}

.react-datepicker__month-read-view {
    visibility: visible !important;
}

// year dropdown arrow

.react-datepicker__year-read-view--selected-year {
    background: #fff;
    border-radius: 50px;
    padding: 8px 22px 8px 10px;
    margin: 5px;
    border: none;
    font-family: 'Inter';

    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
}

.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__year-read-view--down-arrow::after,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow:hover {
    right: 14px !important;
    top: 7px;
    height: 7px;
    width: 7px;
    border-width: 2px 2px 0 0;
}

.react-datepicker__year-read-view {
    visibility: visible !important;
}

.CalenderBox.Right {
    .react-datepicker {
        position: relative;
        left: -12px;
        top: -70px;
    }
}

.CalenderBox.Left {
    .react-datepicker {
        position: relative;
        left: 0px;
        top: -70px;
    }
}

.MultiSelect {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 20px;

    @media screen and (max-width: 1025px) {
        width: 390px;
    }

    @media screen and (max-width: 1000px) {
        width: 422px;
    }

    @media screen and (max-width: 500px) {
        width: 100% !important;
    }

    .FloatingLabelTreeSelection {
        font-size: 11px;
        opacity: 1;
        padding-left: 16px;
        height: 13px;
        font-family: 'SF-Pro-Display';
        font-size: 11px;
        line-height: 3px;
        color: var(--blue);
        top: 14px;
    }

    .ant-select-selection-item-content {
        svg {
            display: none !important;
        }

        p {
            color: #fff !important;
            font-size: 14px;
            margin-top: -2px;
        }
    }

    .ant-select-selection-overflow {
        min-height: 55px;
    }

    .ant-select-multiple.ant-select-disabled.ant-select:not(
            .ant-select-customize-input
        )
        .ant-select-selector {
        background-color: #fff !important;
        color: #fff;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        min-height: 55px;
    }

    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: #fff;
        border-color: none;
        cursor: not-allowed;
    }

    .ant-select-arrow {
        top: 60% !important;
    }
}

.MultiSelectWithTopArrow {
    .ant-select-arrow {
        top: 25% !important;
    }
}

.FloatParent {
    .ant-select-selection-overflow {
        min-height: auto !important;
    }

    .ant-select-arrow {
        top: 40% !important;
        right: 21px;
    }

    .ant-select-selector {
        min-height: auto !important;
    }
}

.ant-select-item-option-state {
    display: none !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #fff !important;
}

.EmptyOption {
    display: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: var(--offwhite);
}

.MultipleSelectMobileWidth {
    @media screen and (max-width: 500px) {
        width: 100% !important;
    }
}

.DropdownMobileWidth {
    @media screen and (max-width: 500px) {
        width: 88% !important;
        min-width: 88% !important;
    }
}

.LockFieldPosition {
    color: var(--blue-2);
    cursor: not-allowed;

    @media screen and (max-width: 600px) {
        left: 445px !important;
    }
}

.mobileMarginRight {
    @media screen and (max-width: 500px) {
        margin-right: 10px;
    }
}

.HalfWidth {
    width: 278px !important;
    min-width: 160px !important;

    @media screen and (max-width: 500px) {
        width: 227px !important;
    }
}

.TreeSelectSpan {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #004aad;
}

.TreeReselectWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TreeReselectText {
    width: 64px;
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #004aad;
}

.react-datepicker__children-container {
    width: 100% !important;
}

.ConsumerCalendarWrapper {
    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__month-year-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__month-year-dropdown-container--scroll {
        margin: 0;
    }

    .react-datepicker__month-read-view--selected-month {
        font-family: 'SF-Pro-Display-Bold';
        background: transparent;
        border: none;
        color: #000f23;
        font-size: 14px;
    }

    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::after,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow:hover {
        border-color: #000f23 !important;
        color: #000f23;
    }

    .react-datepicker__year-read-view--selected-year {
        font-family: 'SF-Pro-Display-Bold';
        background: transparent;
        border: none;
        color: #000f23;
    }

    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__year-read-view--down-arrow::after,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view--down-arrow:hover {
        border-color: #000f23 !important;
        color: #000f23;
    }
}

.ConsumerDateInput {
    padding: 0px 21px 0px 0px;
    width: 334px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    cursor: pointer;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.ConsumerDateInputWrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    .react-datepicker-popper {
        transform: translate3d(-137px, 495px, 0px) !important;
    }

    .ConsumerDateInputImgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        cursor: pointer;
        top: 37.5%;
        left: 0.9px;
        transform: translateY(-50%);
        width: 60px;
        height: 57px;
        background: #f9fcff;
        border-right: 1px solid #e2eeff;
        border-radius: 15px 0px 0px 15px;
    }

    input {
        padding-left: 70px;
        color: #233862;
        padding-top: 16px;
        margin-bottom: 20px;
        font-size: 14px;
        font-family: 'SF-Pro-Display-Light';
    }

    input::placeholder {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 26px;
        color: #233862;
        opacity: 0.78;
        padding-top: 0px;
    }

    .FloatingLabel {
        left: 70px;
    }
}

.InputFields_OptionalText {
    font: 12px/16px var(--font-regular);
    color: #233862;
    margin: 0 0 0 21px;
}

.CalendarFullwidth {
    width: 100%;
    .react-datepicker-wrapper {
        width: 100% !important;
    }
}
