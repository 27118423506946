.TopRectangle {
    width: 410px;
    height: 151px;
    background: #f7f7f7;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    flex-direction: column;
    font-family: 'SF-Pro-Display';
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.Title {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #000000;
    margin-right: 5px;
}

.PaidStatus {
    width: 54px;
    height: 23px;
    background: #edfff5;
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #32cc79;
    text-align: center;
    padding: 5px;
    margin-bottom: 0px;
}

.Subtitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 0px;
    margin-top: 10px;
}

.BottomRectangle {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-family: 'SF-Pro-Display';
}

.BottomRectangleTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    text-transform: uppercase;
    margin-bottom: 5px;
}

.BottomRectangleSubTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    line-height: 25px;
    color: #011a3c;
}

.LeftWrapper {
    width: 200px;
}

.BtnWrapper {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 500px) {
        margin-top: 10px;
    }
}

.OkayBtn {
    width: 134px;
    height: 50px;
    background: #004aad;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.DescriptionWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    margin-left: 64px;
    font-family: 'SF-Pro-Display';
    overflow: scroll;
    max-height: 124px;
    height: 100%;

    @media screen and (max-width: 500px) {
        margin: 30px 20px 0px;
    }
}

.DescriptionText {
    width: 304px;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 13px;
    line-height: 21px;
    color: #011a3c;
    text-transform: none;
}
