.goBackBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 47.76px;

    > svg {
        width: 14.32px;
        height: 14.32px;
        cursor: pointer;
    }
}

.titleAndBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    > h2 {
        font: 18px/22px var(--font-bold);
        color: #011a3c;
        margin: 0;
    }

    > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    @media only screen and (max-width: 500px) {
        > h2 {
            font: 16px/19.09px var(--font-bold);
        }
    }
}

.noLabelInput {
    .lock-icon {
        right: 0px !important;
    }
}

.dropdownTop {
    display: flex;
    align-items: center;
    margin: 22px 30px;
    justify-content: flex-end;

    > h4 {
        width: 100%;
        text-align: left;
        font: 14px/16.71px var(--font-semibold);
        color: #011a3c;
        margin: 0;
    }
}

.selectedFlag {
    width: 69px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e2eeff;
    border-radius: 3px;
    padding: 5.5px 8px;
    font: 10px/12px var(--font-bold);
    color: #004aad;
    letter-spacing: 0.055em;
}

.flagAndBtn {
    display: flex;
    gap: 26px;
    align-items: center;
}

.detailsGrid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    width: 100%;
    row-gap: 12px;

    > div {
        h5 {
            font: 10px/11.93px var(--font-medium);
            color: #011a3cd9;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        p {
            font: 13px/25px var(--font-semibold);
            color: #011a3c;
            margin: 0;
        }
    }
}
