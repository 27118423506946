.wrapper {
    padding: 48px 79px 0 79px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media only screen and (max-width: 500px) {
        padding: 42px 23px 0 23px;
    }
}

.editContentWrapper {
    overflow-y: auto;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.editVariableSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.manageVariableInputsButton {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 4px 10px;
    border-radius: 20px;
    cursor: pointer;

    & > span {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        font-weight: 600;
    }

    & > svg {
        width: 14px;
        height: 14px;
    }
}

.editSectionTitleWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sectionTitle {
        font: 14px/16.71px var(--font-medium);
        text-align: left;
        color: #011a3c;
    }

    .manageVariableInputsButton {
        & > span {
            color: #da1e28;
        }

        & > svg {
            stroke: #da1e28;
            width: 17px;
            height: 17px;
        }
    }
}

.addNewVariableButtonWrapper {
    width: 100%;
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;

    .manageVariableInputsButton {
        & > span {
            color: var(--blue);
        }
    }

    @media only screen and (max-width: 500px) {
        margin-top: 0px;
    }
}

.btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 40px;
    gap: 8px;

    @media only screen and (max-width: 500px) {
        margin-bottom: auto;
    }
}
